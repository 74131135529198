<template>
  <li class="video-listing-item">
    <v-icon
      v-if="item.attributes.assetData.status === 'errored'"
      class="error-icon"
      >error</v-icon
    >

    <template v-else-if="item.attributes.assetData.playbackIds">
      <div v-if="!customThumbnail" class="image-container">
        <template
          v-if="
            item.attributes.imageData.landscape &&
            item.attributes.imageData.landscape.imageKey
          "
        >
          <img
            v-if="item.attributes.imageData.landscape.imageKey"
            :src="`${imageDomain}/${item.attributes.imageData.landscape.imageKey}`"
            class="video-listing-item__image"
          />
        </template>
        <img
          v-else-if="
            item.attributes.assetData.playbackIds[0].policy === 'signed'
          "
          :src="`https://image.mux.com/${item.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?token=${item.attributes.assetData.thumbnailToken}&width=400&height=200`"
          class="video-listing-item__image"
        />
        <img
          v-else
          :src="`https://image.mux.com/${item.attributes.assetData.playbackIds[0].id}/thumbnail.jpg?width=400&height=200`"
          class="video-listing-item__image"
        />

        <v-btn
          v-if="alternativeMode == 'customImage'"
          @click="useCustomThumbnail(true)"
          >Use custom thumbnail</v-btn
        >
      </div>

      <!-- Custom images -->
      <div v-else-if="alternativeMode == 'customImage'" class="image-container">
        <ImageUploader
          :title="'Custom Thumbnail'"
          :widthOptionEnabled="false"
          :hasSmallSpacing="true"
          :mediaLibraryID="imageData ? imageData.mediaLibraryID : null"
          @update="$emit('setCustomThumbnail', $event)"
        />
        <v-btn @click="useCustomThumbnail(false)">Use default thumbnail</v-btn>
      </div>
    </template>

    <div class="video-listing-item__inner">
      <div class="video-listing-item__statuses">
        <p
          v-if="item.attributes.publicationData.published"
          class="status status--published"
        >
          Published
        </p>
        <p v-else class="status status--draft">Draft</p>

        <p
          v-if="item.attributes.assetData.status === 'preparing'"
          class="status status--preparing"
        >
          Asset Preparing
        </p>
        <p
          v-else-if="item.attributes.assetData.status === 'ready'"
          class="status status--ready"
        >
          Asset Ready
        </p>
        <p
          v-else-if="item.attributes.assetData.status === 'errored'"
          class="status status--error"
        >
          Error
        </p>
        <p v-else class="status status">Unknown</p>
      </div>

      <h2 class="video-listing-item__subheading subheading">
        {{ item.attributes.title || "-- No title set --" }}
      </h2>
      <p class="video-listing-item__label">
        Media Type: {{ item.attributes.mediaType }}
      </p>
      <p class="video-listing-item__id">Asset ID: {{ item.id }}</p>

      <div class="video-listing-item__entitlements">
        <span
          v-for="entitlement in item.attributes.accessData.entitlements"
          :key="entitlement"
          class="video-listing-item__entitlements-item"
          >{{ entitlement }}</span
        >

        <span
          v-if="!item.attributes.accessData.entitlements.length"
          class="video-listing-item__entitlements-item--error"
          >No entitlement set</span
        >
      </div>

      <template v-if="item.attributes.assetData.status === 'errored'">
        <p
          v-for="(errorMessage, index) in item.attributes.assetData.errors
            .messages"
          :key="`error-${index}`"
          class="video-listing-item__error-message"
        >
          {{ errorMessage }}
        </p>
      </template>
    </div>
    <template v-if="alternativeMode == 'selection'">
      <v-btn
        v-if="!selectedVideos.includes(item.id)"
        @click="$emit('selected', item)"
        color="accent"
        :disabled="
          !item.attributes.publicationData.published ||
          !item.attributes.accessData.entitlements.length
        "
        >Select</v-btn
      >
      <v-btn v-else @click="$emit('unselected', item.id)" color="accent" outline
        >Selected</v-btn
      >
    </template>
    <v-btn v-else-if="!alternativeMode" :to="`/streamline/ondemand/${item.id}`"
      >Edit</v-btn
    >
    <v-btn
      v-if="alternativeMode == 'customImage'"
      color="error"
      @click="$emit('removed')"
    >
      Remove Item
    </v-btn>
  </li>
</template>


<script>
import ImageUploader from "@/components/Library/ImageUploader";

export default {
  name: "VideoListingItem",

  components: {
    ImageUploader,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    alternativeMode: {
      type: String,
      required: false,
    },
    imageData: {
      type: Object,
      required: false,
    },
    selectedVideos: {
      type: Array,
      default: () => {
        return [];
      },
    },
    customThumbnail: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    imageDomain: process.env.VUE_APP_IMAGEURL,
  }),

  methods: {
    useCustomThumbnail(value) {
      this.$emit("useCustomThumbnail", value);
    },
  },
};
</script>

<style scoped lang="scss">
.video-listing-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 140px;
  margin: 16px 0 48px;
  padding: 32px;
  background-color: #fff;
  border: 1px solid #dfe3e9;
  border-radius: 4px;

  &__image {
    max-width: 120px;
  }

  &__inner {
    padding: 0 0 0 32px;
    width: 100%;
  }

  &__id {
    margin: 0;
    font-size: 11px;
    opacity: 0.5;
    word-break: break-all;
  }

  &__subheading {
    margin: 0;
    padding: 5px 0 0;
    word-break: break-all;
  }

  &__label {
    margin: 4px 0;
    padding: 0;
    font-size: 12px;
    word-break: break-all;
  }

  &__statuses {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__error-message {
    margin: 0 0 12px;
    padding: 6px;
    color: #ff5252;
    border: 1px solid #ff5252;
    word-break: break-all;
  }

  &__entitlements {
    padding-top: 16px;
  }

  &__entitlements-item {
    margin: 0 4px 0 0;
    padding: 2px 3px 3px;
    color: #67247c;
    font-size: 10px;
    background: #e5e5e5;
    border-radius: 4px;
  }

  &__entitlements-item--error {
    margin: 0 4px 0 0;
    padding: 2px 3px 3px;
    color: #fff;
    font-size: 10px;
    background: #ff5252;
    border-radius: 4px;
  }
}

.error-icon {
  color: #ff5252;
}

.image-container {
  * {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.status {
  display: inline-block;
  padding: 3px 12px;
  margin: 0 8px 8px 0;
  font-size: 10px;
  color: #fff;
  background-color: #ccc;
  border-radius: 4px;

  &.status--preparing {
    background-color: color(hiddenHighlight);
  }

  &.status--draft {
    background-color: color(draftHighlight);
  }

  &.status--ready {
    background-color: color(newHighlight);
  }

  &.status--error {
    background-color: #ff5252;
  }

  &.status--published {
    background-color: #67247c;
  }
}
</style>
