<template>
  <div :class="{ 'alternative-mode': alternativeMode }">
    <span v-if="alternativeMode == 'selection'" class="label-selection"
      >Videos Selected: {{ selectedVideos.length }}
      {{
        videoSelectionLimit ? (selectedVideos.length === 5 ? " (max)" : "") : ""
      }}</span
    >
    <div class="video-listing__filters">
      <v-select
        id="publish-state"
        class="video-listing__filter"
        :items="[
          {
            name: 'All',
            value: '',
          },
          {
            name: 'Published',
            value: 1,
          },
          {
            name: 'Draft',
            value: 0,
          },
        ]"
        color="accent"
        outline
        item-value="value"
        item-text="name"
        v-model="filteringBy.publishState"
        @change="changeFilter()"
        label="Publish state"
      ></v-select>

      <!-- <v-select
        id="date-created"
        class="video-listing__filter"
        :items="dateCreatedFilterOptions"
        item-value="value"
        item-text="name"
        color="accent"
        outline
        v-model="filteringBy.dateCreated"
        @change="changeFilter()"
        label="Date"
      ></v-select> -->

      <v-select
        id="assetstate"
        class="video-listing__filter"
        :items="[
          {
            name: 'All',
            value: '',
          },
          {
            name: 'Ready',
            value: 'ready',
          },
          {
            name: 'Preparing',
            value: 'preparing',
          },
        ]"
        color="accent"
        outline
        item-value="value"
        item-text="name"
        v-model="filteringBy.assetState"
        @change="changeFilter()"
        label="Asset"
      ></v-select>

      <v-select
        id="mediaType"
        class="video-listing__filter"
        :items="[
          {
            name: 'All',
            value: '',
          },
          {
            name: 'Video',
            value: 'video',
          },
          {
            name: 'Social Clip',
            value: 'social-clip',
          },
        ]"
        outline
        color="accent"
        item-value="value"
        item-text="name"
        v-model="filteringBy.mediaType"
        @change="changeFilter()"
        label="Media Type"
      ></v-select>
    </div>

    <div class="video-listing__filters video-listing__filters--middle">
      <v-layout class="date-section">
        <v-flex mr-2>
          <label class="label" for="post-published-date">From</label>
          <div class="date-picker__container">
            <datetime v-model="filteringBy.dateCreated[0]"></datetime>
            <v-icon
              v-if="filteringBy.dateCreated[0]"
              class="date-picker__close"
              @click.native="resetDateFilter(0)"
              >close</v-icon
            >
          </div>
        </v-flex>
        <v-flex mr-2 class="date-section">
          <label class="label" for="post-published-date">To</label>
          <div class="date-picker__container">
            <datetime v-model="filteringBy.dateCreated[1]"></datetime>
            <v-icon
              v-if="filteringBy.dateCreated[1]"
              class="date-picker__close"
              @click.native="resetDateFilter(1)"
              >close</v-icon
            >
          </div>
        </v-flex>
      </v-layout>
    </div>
    <div class="video-listing__filters video-listing__filters--middle">
      <v-layout>
        <v-flex mr-4>
          <v-select
            outline
            v-model="filteringBy.category"
            item-text="attributes.name"
            item-value="attributes.name"
            :items="categories"
            flat
            hide-details
            label="Search by Category"
            :disabled="!categories.length"
          ></v-select>
        </v-flex>
        <v-flex mr-4>
          <v-select
            outline
            v-model="sortType"
            item-text="name"
            item-value="value"
            :items="[
              { name: 'Creation date', value: 'created' },
              { name: 'Published date', value: 'published' },
            ]"
            flat
            hide-details
            label="Sort by"
          ></v-select>
        </v-flex>
        <v-flex mr-2>
          <v-text-field
            class="video-listing__search-field"
            v-model="filteringBy.assetID"
            flat
            hide-details
            label="Search by Asset ID"
          />
        </v-flex>
      </v-layout>
    </div>
    <div class="video-listing__search">
      <v-text-field
        class="video-listing__search-field"
        v-model="filteringBy.title"
        flat
        label="Search by title"
      >
      </v-text-field>
      <v-btn
        color="accent"
        class="video-listing__search-button video-listing__search-button--search"
        @click="searchByTitle()"
        >Search</v-btn
      >
      <v-btn
        class="video-listing__search-button video-listing__search-button--clear"
        @click="clearSearch()"
        >Clear Search</v-btn
      >
    </div>

    <p v-if="videosLoaded && resultCount === 1" class="result-count">
      {{ resultCount }} result
    </p>
    <p v-else-if="videosLoaded" class="result-count">
      {{ resultCount }} results
    </p>

    <ol class="video-listing__list" v-if="videosLoaded">
      <video-listing-item
        v-for="item in listing"
        :key="item.id"
        :item="item"
        :isProcessingList="isProcessingList"
        :alternativeMode="alternativeMode"
        :selectedVideos="selectedVideos"
        @selected="$emit('selected', $event)"
        @unselected="$emit('unselected', $event)"
      />

      <p v-if="listing.length === 0">No results</p>
    </ol>

    <div v-else class="video-listing__loading">
      <v-progress-circular indeterminate color="#67247c" />
    </div>

    <div class="pagination" v-if="videosLoaded">
      <v-pagination
        v-model="pageNumber"
        :length="pageCount"
        :total-visible="10"
        circle
        color="accent"
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VideoListingItem from "./VideoListingItem.vue";
import moment from "moment";

export default {
  name: "VideoManagement",

  components: { VideoListingItem },

  props: {
    isProcessingList: {
      type: Boolean,
      default: true,
    },
    alternativeMode: {
      type: String,
      required: false,
    },
    selectedVideos: {
      type: Array,
      required: false,
    },
    videoSelectionLimit: {
      type: Number,
      required: false,
    },
  },

  data: () => ({
    videosApi: process.env.VUE_APP_VIDEOS_API,
    listing: [],
    dateCreatedFilterOptions: [],
    filteringBy: {
      publishState: "",
      dateCreated: [],
      assetState: "",
      mediaType: "",
      title: "",
      assetID: "",
      category: "",
    },
    sortType: "created",
    videosLoaded: false,
    pageNumber: 1,
    pageCount: null,
    resultCount: 0,
    titleSearch: false,
    modules: null,
    categories: [],
  }),

  watch: {
    pageNumber(value, oldValue) {
      console.log("Page number changed");
      if (oldValue) {
        this.videosLoaded = false;
        this.fetchVideos();
      }
    },
  },

  mounted() {
    this.modules = require("@/config/features/" +
      process.env.VUE_APP_CLUB_ID +
      "/modules.js");

    this.getCategories();
    this.fetchVideos();
    // this.createDateFilterArray()
  },

  methods: {
    fetchVideos() {
      // Sergie: 22/06/23 - If this is stable, all the comments below can all be deleted
      // if (this.modules && this.modules.default.LFCExclusive) {
      const filters = [];

      if (this.filteringBy.publishState === 1) {
        filters.push("publicationData.published:1");
      } else if (this.filteringBy.publishState === 0) {
        filters.push("publicationData.published:0");
      }

      if (
        this.filteringBy.dateCreated &&
        (this.filteringBy.dateCreated[0] || this.filteringBy.dateCreated[1])
      ) {
        const date1 = this.filteringBy.dateCreated[0]
          ? moment.utc(this.filteringBy.dateCreated[0]).format("YYYY-MM-DD")
          : "*";
        const date2 = this.filteringBy.dateCreated[1]
          ? moment.utc(this.filteringBy.dateCreated[1]).format("YYYY-MM-DD")
          : "*";
        filters.push(`publicationData.createdAt:[${date1} TO ${date2}]`);
      }

      if (this.filteringBy.assetState) {
        filters.push(`assetData.status:${this.filteringBy.assetState}`);
      }

      if (this.filteringBy.mediaType) {
        filters.push(`mediaType:${this.filteringBy.mediaType}`);
      }

      if (this.filteringBy.title) {
        filters.push(`title:"${this.filteringBy.title}"`);
      }

      if (this.filteringBy.assetID) {
        filters.push(`videoID:"${this.filteringBy.assetID}"`);
      }

      if (this.filteringBy.category) {
        filters.push(`tagData.categories:"${this.filteringBy.category}"`);
      }

      let filterQuery = "";

      if (filters.length) {
        filterQuery = `&q=(${filters.join(" AND ")})`;
      }

      const sortOptions = {
        created: "publicationData.createdAt:desc",
        published: "publicationData.startAt:desc",
      };

      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios
          .get(
            `${
              process.env.VUE_APP_STREAMLINEAPI
            }/search?page[size]=20&page[number]=${
              this.pageNumber
            }${filterQuery}&sort=${sortOptions[this.sortType || "created"]}`,
            { headers: { Authorization: jwt } }
          )
          .then((response) => {
            console.log("response", response);
            this.listing = response.data.data;
            this.pageCount = response.data.meta.totalPages;
            this.resultCount = response.data.meta.totalCount;
            this.videosLoaded = true;
          })
          .catch((e) => {
            console.error("Problem retrieving videos", e);
            this.videosLoaded = true;
          });
      });
      // } else {
      //   let publishFilter;
      //   if (this.filteringBy.publishState === 1) {
      //     publishFilter = `&filter[publicationData.published,eq,number]=1`;
      //   } else if (this.filteringBy.publishState === 0) {
      //     publishFilter = `&filter[publicationData.published,eq,number]=0`;
      //   } else {
      //     publishFilter = "";
      //   }

      //   let assetStateFilter;
      //   if (this.filteringBy.assetState) {
      //     assetStateFilter = `&filter[assetData.status,contains]=${this.filteringBy.assetState}`;
      //   } else {
      //     assetStateFilter = "";
      //   }

      //   let mediaTypeFilter;
      //   if (this.filteringBy.mediaType) {
      //     mediaTypeFilter = `&filter[mediaType,contains]=${this.filteringBy.mediaType}`;
      //   } else {
      //     mediaTypeFilter = "";
      //   }

      //   let titleFilter;
      //   if (this.filteringBy.title) {
      //     titleFilter = `&filter[title,contains,string]=${this.filteringBy.title}`;
      //   } else {
      //     titleFilter = "";
      //   }

      //   this.$getCurrentUserJwToken().subscribe((jwt) => {
      //     axios
      //       .get(
      //         `${process.env.VUE_APP_STREAMLINEAPI}/videos?page[size]=20&page[number]=${this.pageNumber}${publishFilter}${assetStateFilter}${mediaTypeFilter}${titleFilter}`,
      //         { headers: { Authorization: jwt } }
      //       )
      //       .then((response) => {
      //         console.log("response", response);
      //         this.listing = response.data.data;
      //         this.pageCount = response.data.meta.totalPages;
      //         this.videosLoaded = true;
      //       })
      //       .catch((e) => {
      //         console.error("Problem retrieving videos", e);
      //         this.videosLoaded = true;
      //       });
      //   });
      // }
    },

    getCategories() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.videosApi}/categories`,
          headers: { Authorization: jwt },
        })
          .then((response) => {
            console.log("retrieved CATEGORIES settings:", response.data.data);
            this.categories = response.data.data;
          })
          .catch((e) => {
            console.error("Problem getting CATEGORIES settings", e);
          });
      });
    },

    changeFilter() {
      this.pageNumber = 1;
      this.videosLoaded = false;
      this.fetchVideos();
    },

    searchByTitle() {
      this.pageNumber = 1;
      this.videosLoaded = false;
      // this.filteringBy.publishState = ''
      // this.filteringBy.assetState = ''
      // this.filteringBy.mediaType = ''

      // this.titleSearch = true
      this.fetchVideos();
    },

    clearSearch() {
      this.videosLoaded = false;
      // this.titleSearch = false
      this.filteringBy.title = "";
      this.filteringBy.publishState = "";
      this.filteringBy.assetState = "";
      this.filteringBy.mediaType = "";
      this.filteringBy.dateCreated = [];
      this.fetchVideos();
    },

    // createDateFilterArray() {
    //   const monthsGenerate = () => {
    //     const months = [
    //       {
    //         name: 'All',
    //         value: ''
    //       }]
    //     const dateStart = this.$moment()
    //     const dateEnd = this.$moment().subtract(10, 'year')
    //     while (dateEnd.diff(dateStart, 'months') <= 0) {
    //       months.push(          {
    //         name: dateStart.format('MMM Y'),
    //         value: dateStart.format('Y-MM')
    //       })
    //       dateStart.subtract(1, 'month')
    //     }
    //     return months
    //   }

    //   this.dateCreatedFilterOptions = monthsGenerate()
    // }

    resetDateFilter(index) {
      this.filteringBy.dateCreated[index] = "";
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped lang="scss">
.video-listing {
  &__filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    padding: 16px 16px 0;
    border: 1px solid #dfe3e9;
    border-radius: 4px 4px 0 0;

    &--middle {
      border-top: none;
      border-radius: 0px;
      padding-bottom: 16px;
    }
  }

  &__filter {
    margin: 0 16px 0 0;
    width: calc(50% - 16px);
  }

  &__search {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: #fff;
    margin: 0 0 48px;
    padding: 32px 16px 0;
    border: 1px solid #dfe3e9;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    box-shadow: 4px 4px 4px 0 #ebebeb;
  }

  &__search-field {
    // max-width: 50%;
  }

  &__list {
    padding: 0;
    margin: 20px 0 0;
  }

  &__loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 16px auto;
    width: 100%;
    text-align: center;
  }
}

.alternative-mode {
  padding: 40px 20px;
  position: relative;
}

.label-selection {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px;
  background: peachpuff;
  border-radius: 0 0 6px 6px;
}

.result-count {
  margin: 0 0 32px;
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  font-style: italic;
  text-align: center;
}

.date-picker {
  flex: auto;
  display: flex;
  flex-direction: column;
  margin: 0 16px 8px 0;
  width: 100%;
  border: 1px solid rgb(223, 227, 233);
  position: relative;

  &__title {
    color: rgb(36, 0, 47);
    font-size: 11px;
    font-family: rubik, sans-serif;
    font-weight: 500;
    position: absolute;
    top: 18px;
    left: 12px;
  }
  &__value {
    padding-top: 24px;
    font-size: 16px;
    color: rgba($color: #000000, $alpha: 0.87);
    margin-bottom: 0;
  }
}

.date-section {
}

.date-picker {
  &__container {
    position: relative;
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
  }
}
</style>
